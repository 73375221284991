import React from 'react';
import './search-box.style.css';

// function components can't  inherit the react component class so it has no access to internal state. It also doesn;t have access to life cycle methods. 
export const SearchBox = ({placeholder,handleChange})=> {
    return (

        <input type="search" className="search" placeholder={placeholder} onChange={handleChange} />

    );
};